@if ((user$ | async) && (tokenService.isLoggedIn$ | async)) {
  <div class="user-menu-container">
    <div class="header">
      @if (emulationService.isEmulating) {
        <button (click)="emulationService.removeEmulation()" class="stop-emulation-icon">
          <mat-icon>person</mat-icon>
          <p>{{ 'header.user-menu.menu-item-stop-emulate' | translate }}</p>
        </button>
      }

      <button (click)="toggleMenu()" class="no-style menu-toggle" [attr.aria-label]="((isMenuVisible$ | async) ? 'header.user-menu.close' : 'header.user-menu.open') | translate">
        @if (userImageSrc$ | async) {
          <div class="image" preventRightClick [ngStyle]="{ 'background-image': 'url(' + (userImageSrc$ | async) + ')' }"></div>
        } @else {
          <mat-icon class="image fallback-image">person</mat-icon>
        }

        <div class="user-info">
          <p showTooltipIfTruncated class="bold">{{ userDetails$ | async | name }}</p>
          @if (userDetails$ | async; as userDetails) {
            <p showTooltipIfTruncated class="org-code">
              {{ userDetails?.paOrgCode }}{{ userDetails?.paOrgCode && userDetails?.placeOfAction ? ', ' : '' }}{{ userDetails?.placeOfAction }}
            </p>
          }
        </div>
        <mat-icon [@toggleIcon]="(isMenuVisible$ | async) ? 'open' : 'closed'" class="toggle-icon">keyboard_arrow_down</mat-icon>
      </button>

      @if (remainingTokenTimeText$ | async; as remainingTime) {
        <button class="no-style token-time" (click)="renewToken()" [attr.aria-label]="'header.user-menu.refresh-token' | translate">
          <mat-icon class="clock">access_time</mat-icon>
          <mat-icon class="renew">autorenew</mat-icon>
          <p [class.warning]="(tokenTimeWarningStyle$ | async) === 'alarming'" [class.error]="(tokenTimeWarningStyle$ | async) === 'urgent'" class="bold">{{ remainingTime }}</p>
        </button>
      }
    </div>

    @if (isMenuVisible$ | async) {
      <coin-v2-card padding="none" @fade>
        <div class="arrow-up"></div>
        @for (menuItem of menuItems$ | async; track menuItem) {
          <div class="menu-item" role="menu">
            <ng-container *ngTemplateOutlet="menuItemTemplate; context: { menuItem }"></ng-container>
          </div>
        }
      </coin-v2-card>
    }
  </div>
}

<ng-template #menuItemTemplate let-menuItem="menuItem">
  <button (click)="openMenuItem(menuItem)" class="no-style" role="menuitem">
    @if (menuItem.icon) {
      <mat-icon class="menu-item-icon">{{ menuItem.icon }}</mat-icon>
    }
    <p>{{ menuItem.name | translate }}</p>
    @if (menuItem.subMenuItems?.length > 0) {
      <mat-icon class="sub-menu-toggle" [class.open]="menuItem.submenuactive">keyboard_arrow_right</mat-icon>
    }
  </button>

  @if (menuItem.submenuactive) {
    <coin-v2-card padding="none" @fade>
      @for (subMenuItem of menuItem.subMenuItems; track subMenuItem) {
        <div class="sub-menu-item">
          <ng-container *ngTemplateOutlet="menuItemTemplate; context: { menuItem: subMenuItem }"></ng-container>
        </div>
      }
    </coin-v2-card>
  }
</ng-template>
